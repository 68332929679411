import React from "react";
import "./Safety.css";

const Safety = () => {
  return (
    <div className="safety-parent-div">
      <h1>This is sasety</h1>
    </div>
  );
};

export default Safety;
