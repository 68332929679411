import React from "react";
import "./RespectPolice.css";

const RespectPolice = () => {
  return (
    <div>
      <p>Respect Police</p>
    </div>
  );
};

export default RespectPolice;
