import React from "react";
import "./WebMail.css";
const WebMail = () => {
  return (
    <div>
      <h1>This is WebMail</h1>
    </div>
  );
};

export default WebMail;
