import React from "react";
import "./Login.css";

const Login = () => {
  return (
    <div>
      <h1>This is login</h1>
    </div>
  );
};

export default Login;
