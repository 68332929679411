import React from "react";
import "./Media.css";
const Media = () => {
  return (
    <div>
      <h1>Media</h1>
    </div>
  );
};

export default Media;
