import React from "react";

const Blog = () => {
  return (
    <div>
      <h2>This is blog</h2>
    </div>
  );
};

export default Blog;
