import React from "react";
import "./HowTripShipTaskWork.css";

const HowTripShipTaskWork = () => {
  return (
    <div>
      <h1>This howTripShipWork</h1>
    </div>
  );
};

export default HowTripShipTaskWork;
